import {combineReducers, configureStore} from '@reduxjs/toolkit';
import { persistReducer, persistStore} from 'redux-persist';
import userReducer from './features/userSlice';
import thunk from 'redux-thunk';
import whiteReducer from './features/whiteSlice';
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
  user: userReducer,
  white: whiteReducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['user'],
  whitelist: ['white'],
};

const persistReducers = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistReducers,
  middleware: [thunk],
});

const persistor = persistStore(store);

export { persistor };

export const getToken = () => store?.getState()?.user?.token;
// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;