import "./App.css";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./screens/Login";
import Home from "./screens/Home";
import Dashboard from "./screens/Dashboard";
import User from "./screens/User";
import { PrivacyLink, RefundLink, ShippingLink, TermsLink } from "./components";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Home />
      </div>
    ),
  },
  {
    path: "/login",
    element: (
      <div>
        <Login />
      </div>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <div>
        <Dashboard />
      </div>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <div>
        <PrivacyLink />
      </div>
    ),
  },
  {
    path: "/terms-conditions",
    element: (
      <div>
        <TermsLink />
      </div>
    ),
  },
  {
    path: "/shipping-delivery",
    element: (
      <div>
        <ShippingLink />
      </div>
    ),
  },
  {
    path: "/cancellation-refund",
    element: (
      <div>
        <RefundLink />
      </div>
    ),
  },
]);

const router1 = createBrowserRouter([
  {
    path: "/dashboard",
    element: (
      <div>
        <Dashboard />
      </div>
    ),
  },
  {
    path: "/user",
    element: (
      <div>
        <User />
      </div>
    ),
  },
  {
    path: "/",
    element: (
      <div>
        <Home />
      </div>
    ),
  },
]);

function App() {
  const { tokens } = useSelector((state) => state?.white);
  return (
    <div>
      {!tokens || tokens === null || tokens === "" ? (
        <RouterProvider router={router} />
      ) : (
        <RouterProvider router={router1} />
      )}
    </div>
  );
}

export default App;
