import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAvkss2Hv7z2kqPy2z77jlFf8x0x-lHRO4",
  authDomain: "mahdi-tech.firebaseapp.com",
  databaseURL: "https://mahdi-delivry.firebaseio.com/",
  projectId: "mahdi-tech",
  storageBucket: "mahdi-tech.appspot.com",
  messagingSenderId: "342174197870",
  appId: "1:342174197870:web:1a4fcd705dee478f6c9e7f"
};
//   databaseURL: "https://mahdi-tech-default-rtdb.firebaseio.com",

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app)
export { database, firestore }