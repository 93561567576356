import React, { useState } from "react";
import loginImg from "../assets/login_image.jpg";
import { AuthenticationService} from "../service";
import { CountryCode } from "../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginer, setUser, takeToken } from "../features/whiteSlice";
import { CountryCodeDropdown } from "../components";
import { getDoc, doc} from "firebase/firestore";
import { firestore } from "../secret/firebaseConfig";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [mob, setMob] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState(
    CountryCode.find((country) => country.name === "India").dial_code
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logIn = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    setLoading(true);
    let user = {
      code,
      mob,
      password,
    };
    await AuthenticationService.login(user)
      .then((response) => {
        setLoading(false);
        if (response?.status) {
          fetchLoginer(response?.user?.mob)
          // dispatch(setLoginer(getDisplayService.getDisplayMerchant(response?.user?.mob)))
          dispatch(setUser(response?.user));
          if (response?.data) navigate("/dashboard", { replace: true });
          dispatch(takeToken(response?.data));
          setErrorMessage("");
        } else {
          setLoading(false);
          setErrorMessage(response?.message);
        }
      })
      .catch((e) => console.log("e", e));
  };

  const handleCountrySelect = (country) => {
    setCode(country);
  };

  const fetchLoginer = async (mob) => {
    try {
      const docRef = doc(firestore, "Users", mob);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        dispatch(setLoginer(docSnap.data().merchant));
      } else {
        console.log("No loginer document!");
      }

    } catch (error) {
      console.error("Error fetching subcollection data:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
      <div className="hidden sm:block">
        <img className="w-full h-full object-cover" src={loginImg} alt="" />
      </div>
      <div className="bg-gray-800 flex flex-col justify-center">
        <form
          onSubmit={(e) => logIn(e)}
          className="max-w-[400px] w-full mx-auto bg-gray-900 p-8 px-8 rounded-lg"
        >
          <h2 className="text-4xl dark:text-white font-bold text-center">
            SIGN IN
          </h2>
          <div className="flex flex-col text-gray-400 py-2">
            <label htmlFor="phoneNumber">Select Country Code</label>
            <CountryCodeDropdown onSelect={handleCountrySelect} />
          </div>
          <div className="flex flex-col text-gray-400 py-2">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              id="phoneNumber"
              value={mob}
              required
              placeholder="1234567890"
              onChange={(e) => setMob(e.target.value)}
              className="mt-2 p-2 rounded-lg bg-gray-700 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
              type="text"
            />
          </div>
          <div className="flex flex-col text-gray-400 py-2">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              value={password}
              required
              placeholder="password"
              onChange={(e) => setPassword(e.target?.value)}
              className="mt-2 p-2 rounded-lg bg-gray-700 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
              type="password"
            />
          </div>
          <div className="flex justify-between text-gray-400 py-2">
            <p className="flex items-center">
              <input className="mr-2" type="checkbox" />
              Remember Me
            </p>
            <p>Forgot Password</p>
          </div>
          <div className="flex items-center justify-center">
            <p className="text-red-400">{errorMessage}</p>
          </div>
          {loading ? (
            <button
              type="button"
              className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded-lg disabled:cursor-not-allowed"
              disabled
            >
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded-lg"
            >
              Sign In
            </button>
          )}
          <span className="flex text-xs text-red-500 items-center justify-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.mahdi"
              className="underline  text-[#fff]"
            >
              Sign up through Mahdi App
            </a>
          </span>
        </form>
      </div>
    </div>
  );
};

export default Login;
