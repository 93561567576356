import { BASE_URL } from "../secret/Config";

const STATIC_IMAGE = {
  BASE_URL: `${BASE_URL.url}/Archive`,
  RESTAURANT_URL: `${BASE_URL.url}/restaurant`,
  VIDEO_HOME: `${BASE_URL.video_home}`
};

const BACKEND_API = {
    BASE_API_URL: `${BASE_URL.url}/api`,
    BASE_RAPI_URL: `${BASE_URL.url2}/api`,
    REGISTER: '/register',
    LOGIN: '/login',
    LOGINUPDATE: '/loginupdate',
    DELET_USER: '/deleteUser',
    QURAN_SURAH: 'quran_surah',
    WEATHER: `${BASE_URL.weather}`,
    SALAT_TIME: 'salat_time',
    HATHEES: `${BASE_URL.hathees}`,
    MAP_API: `${BASE_URL.map_api_key}`,
  };

  export default { BACKEND_API, STATIC_IMAGE };