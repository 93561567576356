import React from "react";

const RefundLink = () => {
  return (
    <a href="/cancellation-refund" className="cursor-pointer hover:underline">
      <span
        style={{
          color: "red",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
          fontFamily: "your-metal-font",
          fontSize: "17px",
          padding: "5px",
          border: "1px solid #d0d0d0",
          borderRadius: "5px",
        }}
      >
        Refund
      </span>
    </a>
  );
};

export default RefundLink;
