import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  token: '',
  positionr: {
    latitude: 0,
    longitude: 0,
  },
  weatherloc: '',
  isAppLoading: true,
  salatdata: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setPosition: (state, action) => {
      state.positionr = action.payload;
    },
    setWeatherLoc: (state, action) => {
      state.weatherloc = action.payload;
    },
    setAppLoading: (state, action) => {
      state.isAppLoading = action.payload;
    },
    setSalatData: (state, action) => {
      state.salatdata = action.payload;
    },
  },
});

export const {
  setToken,
  setPosition,
  setWeatherLoc,
  setAppLoading,
  setSalatData,
} = userSlice.actions;
export default userSlice.reducer;