import { useState } from 'react';
import { CountryCode } from '../constants';

const CountryCodeDropdown = ({ onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    CountryCode.find(country => country.name === 'India'),
  );

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{selectedCountry?.dial_code}</span>
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-screen overflow-y-auto">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {CountryCode.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  onSelect(item);
                  setIsOpen(false);
                  setSelectedCountry(item);
                }}
                className="block px-7 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                role="menuitem"
              >
                {`${item.name} (${item.dial_code}) (${item?.code})`}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryCodeDropdown;