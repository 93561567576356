import React, { useState, useEffect } from "react";
import { FiAlignRight, FiLogOut, FiUser } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setUser, takeToken } from "../features/whiteSlice";
import { ref, get } from "firebase/database";
import { database, firestore } from "../secret/firebaseConfig";
import { getDoc, doc, collection, getDocs } from "firebase/firestore";

const Dashboard = () => {
  const { user, loginer } = useSelector((state) => state?.white);

  const [open, setOpen] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [fetchedData, setFetchedData] = useState(null);
  const [totalWallet, setTotalWallet] = useState(0);
  const [isAdmin, setisAdmin] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menus = [
    { name: "User", link: "/user", icon: FiUser },
    { name: "Logout", link: "/", icon: FiLogOut },
  ];

  useEffect(() => {
    fetchCartData();
    // eslint-disable-next-line
  }, [loginer]);

  const fetchData = async (bookingId) => {
    try {
      const docRef = doc(firestore, "Users", bookingId);
      const docSnap = await getDoc(docRef);
      let data = {};
      if (docSnap.exists()) {
        data = { ...docSnap.data() };
      } else {
        console.log("No such document!");
      }

      const subDocRefDelivery = collection(docRef, "delivery");
      const subDocSnapDelivery = await getDocs(subDocRefDelivery);
      const deliveryData = [];
      subDocSnapDelivery.forEach((doc) => {
        deliveryData.push({ id: doc.id, ...doc.data() });
      });
      // data.delivery = deliveryData;

      if (loginer !== "admin" && loginer !== "") {
        // console.log('data filet',bookingsData.filter(item => item.merchant === loginer))
        data.delivery = deliveryData.filter(
          (item) => item.merchant === loginer
        );
      } else {
        data.delivery = deliveryData;
      }

      const totalDeliveryAmount = deliveryData.reduce((sum, delivery) => {
        return sum + (delivery.wallet || 0); // Ensure that the wallet value is not undefined
      }, 0);

      const totalMerchantDeliveryAmount = deliveryData
        .filter((item) => item.merchant === loginer)
        .reduce((sum, booking) => {
          return sum + (booking.wallet || 0); // Ensure that the wallet value is not undefined
        }, 0);

      const subDocRef = collection(docRef, "bookings");
      const subDocSnap = await getDocs(subDocRef);
      const bookingsData = [];
      subDocSnap.forEach((doc) => {
        bookingsData.push({ id: doc.id, ...doc.data() });
      });
      if (loginer !== "admin" && loginer !== "") {
        // console.log('data filet',bookingsData.filter(item => item.merchant === loginer))
        data.bookings = bookingsData.filter(
          (item) => item.merchant === loginer
        );
      } else {
        data.bookings = bookingsData;
      }

      const totalBookingAmount = bookingsData.reduce((sum, booking) => {
        return sum + (booking.wallet || 0); // Ensure that the wallet value is not undefined
      }, 0);

      const totalMerchantBookingAmount = bookingsData
        .filter((item) => item.merchant === loginer)
        .reduce((sum, booking) => {
          return sum + (booking.wallet || 0); // Ensure that the wallet value is not undefined
        }, 0);

      setFetchedData(data);
      setTotalWallet(totalBookingAmount + totalDeliveryAmount);
      if (loginer !== "admin" && loginer !== "") {
        setTotalWallet(
          totalMerchantBookingAmount + totalMerchantDeliveryAmount
        );
      } else {
        setTotalWallet(totalBookingAmount + totalDeliveryAmount);
      }
    } catch (error) {
      console.error("Error fetching subcollection data:", error);
    }
  };

  const fetchCartData = async () => {
    try {
      const useRef = ref(database);
      await get(useRef).then((snapshot) => {
        const data = snapshot.val();
        if (data) {
          if (loginer === "admin") {
            const flattenedData = flattenData([data]);
            setBookings(flattenedData);
            setisAdmin(true);
          } else if (loginer === "") {
            const userSpecificData = data[user?.mob];
            setBookings(userSpecificData);
            setisAdmin(false);
          } else {
            const arrayOnly = flattenData([data]);
            const marchatVisit = arrayOnly.filter(
              (item) => item.loadimage === loginer
            );
            setBookings(marchatVisit);
            setisAdmin(false);
          }
          // console.log('loginer',loginer)
        }
      });
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const flattenData = (data) => {
    const result = [];
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === "object" && !Array.isArray(item[key])) {
          result.push(...item[key], key);
        } else if (Array.isArray(item[key])) {
          result.push(...item[key], key);
        }
      });
    });
    return result;
  };

  return (
    <section className="flex gap-6">
      <div
        className={`bg-[#0e0e0e] min-h-screen ${
          open ? "w-72" : "w-16"
        } duration-500 text-gray-100 px-4`}
      >
        <div className="py-3 flex justify-end">
          <FiAlignRight
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />
        </div>
        {open && (
          <div className="px-4 py-3 flex items-center justify-center border-b border-gray-700">
            <h1 className="text-white text-lg font-extrabold cursor-pointer">
              Dashboard
            </h1>
          </div>
        )}
        <div className="mt-4 flex flex-col gap-4">
          {menus.map((menu, i) => (
            <div
              key={i}
              onClick={() => {
                if (menu.link === "/") {
                  dispatch(takeToken(""));
                  navigate("/", { replace: true });
                  dispatch(setUser({}));
                }
              }}
            >
              <Link
                to={menu.link}
                className={`flex items-center text-sm gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md ${
                  menu.margin && "mt-5"
                }`}
              >
                {React.createElement(menu.icon, { size: 20 })}
                <h2
                  className={`whitespace-pre duration-500 ${
                    !open && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
                  style={{ transitionDelay: `${i}0ms` }}
                >
                  {menu.name}
                </h2>
                <h2
                  style={{
                    flex: 1,
                    position: "absolute",
                    left: 20,
                    marginTop: 100,
                  }}
                  className={`${
                    open && "hidden"
                  } overflow-hidden group-hover:w-fit  group-hover:duration-300`}
                >
                  {React.createElement(menu.icon, { size: 20 })}
                  {/* {menu.name} */}
                </h2>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-wrap justify-start">
        {!bookings ? (
          <div className="w-full flex  absolute top-40">
            <p className="text-gray-700 text-center">
              You have not ordered any items. Please choose a booking or
              delivery through the Mahdi app.
            </p>
          </div>
        ) : (
          bookings.map((booking, index) => (
            <div
              key={index.toString()}
              className="bg-white shadow-md rounded-lg flex-wrap "
            >
              {booking.name ? (
                <>
                  <h3 className="text-sm font-medium">{booking.name}</h3>
                  <div className="text-gray-500">
                    <p>Quantity: {booking.quantity}</p>
                    <p>Rate: ₹{booking.rate}</p>
                  </div>
                  {booking.image && (
                    <img
                      src={booking.image}
                      alt={booking.name}
                      className="w-full h-24 object-cover mt-2"
                    />
                  )}
                  {isAdmin ? null : (
                    <button
                      onClick={() => fetchData(booking.mob)}
                      className="mt-5 py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                    >
                      {booking.mob}
                    </button>
                  )}
                </>
              ) : (
                <button
                  onClick={() => fetchData(booking)}
                  className="mt-5 py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                >
                  {booking}
                </button>
              )}
            </div>
          ))
        )}
      </div>

      {fetchedData && (
        <div
          className=" bg-white shadow-md rounded-lg 
              overflow-x-auto flex-wrap" // Added responsive styles
        >
          <h3 className="text-lg font-medium">Order Details</h3>
          <h3 className="text-lg font-medium">Total Paid : {totalWallet}</h3>
          <div className="mt-2">
            <h3 className="text-sm font-medium p-4">
              user mob : {fetchedData?.mob}
            </h3>
            <h3 className="text-sm font-medium p-4">
              Email : {fetchedData?.email}
            </h3>
            <h3 className="text-sm font-medium p-4">
              Town : {fetchedData?.locality}
            </h3>
            <h3 className="text-sm font-medium p-4">
              District : {fetchedData?.city}
            </h3>
          </div>
          {fetchedData.delivery && (
            <div className="mt-4">
              <h4 className="text-md font-medium">Delivery Data</h4>
              {fetchedData.delivery.map((delivery, index) => (
                <div
                  key={index}
                  className="p-2 border border-gray-200 rounded mt-2"
                >
                  <h3 className="text-sm font-medium p-4">
                    status : {delivery?.Del_status}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Merchant : {delivery?.merchant}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Latitude : {delivery?.DeliveryLat}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Longitude : {delivery?.DeliveryLng}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    PaymentId : {delivery?.paymentId}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Amount : {delivery?.wallet}
                  </h3>
                  <pre className="whitespace-pre-wrap break-all">
                    {delivery?.items.map((item, index) => {
                      return (
                        <div
                          className="bg-white shadow-md rounded-lg p-4 m-2 w-full "
                          key={index.toString()}
                        >
                          <h3 className="text-sm font-medium p-4">
                            Product name : {item?.name}
                          </h3>
                          <h3 className="text-sm font-medium p-4">
                            Quantity : {item?.quantity}
                          </h3>
                        </div>
                      );
                    })}
                  </pre>
                </div>
              ))}
            </div>
          )}
          {fetchedData.bookings && (
            <div className="mt-4">
              <h4 className="text-md font-medium">Bookings Data</h4>
              {fetchedData.bookings.map((booking, index) => (
                <div
                  key={index}
                  className="p-2 border border-gray-200 rounded mt-2"
                >
                  <h3 className="text-sm font-medium p-4">
                    status : {booking?.Del_status}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Merchant : {booking?.merchant}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Date : {booking?.booking_date}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Time : {booking?.booking_time}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    PaymentId : {booking?.paymentId}
                  </h3>
                  <h3 className="text-sm font-medium p-4">
                    Amount : {booking?.wallet}
                  </h3>
                  <pre className="whitespace-pre-wrap break-all">
                    {booking?.items.map((item, index) => {
                      return (
                        <div
                          className="bg-white shadow-md rounded-lg p-4 m-2 w-full "
                          key={index.toString()}
                        >
                          <h3 className="text-sm font-medium p-4">
                            Product name : {item?.name}
                          </h3>
                          <h3 className="text-sm font-medium p-4">
                            Quantity : {item?.quantity}
                          </h3>
                        </div>
                      );
                    })}
                  </pre>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Dashboard;
