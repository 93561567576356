import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isFirst: '',
  user: {},
  fingerr: false,
  touchr: true,
  fingerno: true,
  tokens: '',
  loginer: '',
  rimage: ''
};

const whiteSlice = createSlice({
  name: 'white',
  initialState,
  reducers: {
    setIsFirst: (state, action) => {
      state.isFirst = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setFinger: (state, action) => {
      state.fingerr = action.payload;
    },
    setTouchR: (state, action) => {
      state.touchr = action.payload;
    },
    setFingerNo: (state, action) => {
      state.fingerno = action.payload;
    },
    takeToken: (state, action) => {
      state.tokens = action.payload;
    },
    setLoginer: (state, action) => {
      state.loginer = action.payload;
    },
    setImage: (state, action) => {
      state.rimage = action.payload;
    }
  },
});

export const {
  setIsFirst,
  setFingerNo,
  setImage,
  setUser,
  setFinger,
  setTouchR,
  takeToken,
  setLoginer,
} = whiteSlice.actions;

export default whiteSlice.reducer;
