import axios from 'axios';
import { ApiConstants } from '../constants';

const AuthRequest = axios.create({
    baseURL: ApiConstants.BACKEND_API.BASE_RAPI_URL,
  });

const AuthRequest2 = axios.create({
    baseURL: ApiConstants.BACKEND_API.BASE_API_URL,
  });

  const login = async (user) => {
    if (!user?.mob || !user?.password) {
      return {status: false, message: 'Please fill up all fields'};
    }
    try {
      let requestBody = {
        code: user?.code,
        mob: user?.mob,
        password: user?.password,
      };
      let loginResponse = await AuthRequest.post(
        ApiConstants.BACKEND_API.LOGIN,
        requestBody,
      );
      return loginResponse?.data;
    } catch (error) {
      console.log(error);
      return {status: false, message: 'Oops! Something went wrong'};
    }
  };

  const deleteUser = async (user) => {
    if (!user?.mob || !user?.password) {
      return {status: false, message: 'Please fill up all fields'};
    }
    try {
      let requestBody = {
        code: user?.code,
        mob: user?.mob,
        password: user?.password
      };
      let userResponse = await AuthRequest2.post(
        ApiConstants.BACKEND_API.DELET_USER,
        requestBody,
      );
      return userResponse?.data;
    } catch (error) {
      console.log(error);
      return {status: false, message: 'Oops! Something went wrong'};
    }
  };

  export default {login, deleteUser};