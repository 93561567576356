import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser, takeToken } from "../features/whiteSlice";
import { AuthenticationService } from "../service";
import { CountryCode } from "../constants";
import { CountryCodeDropdown } from "../components";

const User = () => {
  const [mob, setMob] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState(
    CountryCode.find((country) => country.name === "India").dial_code
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { user } = useSelector((state) => state?.white);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDelete = async (e) => {
    e.preventDefault();
    if (user?.mob === mob) {
      setLoading(true);
      let userToDelete = {
        code,
        mob,
        password,
      };
      AuthenticationService.deleteUser(userToDelete)
        .then((response) => {
          setLoading(false);
          if (response?.status) {
            dispatch(setUser({}));
            dispatch(takeToken(""));
            setErrorMessage("");
            navigate("/", { replace: true });
          } else {
            setLoading(false);
            setErrorMessage(response?.message);
          }
        })
        .catch((e) => console.log("e", e));
    } else {
      setErrorMessage("Only loginer can delete data");
    }
  };

  const handleCountrySelect = (country) => {
    setCode(country);
  };

  return (
    <div className="max-w-6xl mx-auto bg-white rounded-md overflow-hidden shadow-md p-6 mt-6">
      <h2 className="text-3xl font-bold mb-6 text-center text-teal-600">
        User Information
      </h2>
      {user ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="max-w-md mx-auto bg-white rounded-md overflow-hidden shadow-2xl p-6">
            <div className="mb-4 flex justify-between" >
              <label className="block text-gray-600 text-sm font-semibold mb-2">
                Email:
              </label>
              <p className="text-gray-800">{user.email}</p>
            </div>
            <div className="mb-4 flex justify-between">
              <label className="block text-gray-600 text-sm font-semibold mb-2">
                Country Code:
              </label>
              <p className="text-gray-800">{user.code}</p>
            </div>
            <div className="mb-4 flex justify-between">
              <label className="block text-gray-600 text-sm font-semibold mb-2">
                Mobile Number:
              </label>
              <p className="text-gray-800">{user.mob}</p>
            </div>
            <div className="mb-4 flex justify-between">
              <label className="block text-gray-600 text-sm font-semibold mb-2">
                Created At:
              </label>
              <p className="text-gray-800">{user.createdAt}</p>
            </div>
            <div className="mb-4 flex justify-between">
              <label className="block text-gray-600 text-sm font-semibold mb-2">
                City:
              </label>
              <p className="text-gray-800">{user.city}</p>
            </div>
            <div className="mb-4 flex justify-between">
              <label className="block text-gray-600 text-sm font-semibold mb-2">
                Locality:
              </label>
              <p className="text-gray-800">{user.locality}</p>
            </div>
            <div className="mb-4 flex justify-between">
              <label className="block text-gray-600 text-sm font-semibold mb-2">
                Wallet:
              </label>
              <p className="text-gray-800">{user?.wallet}</p>
            </div>
          </div>
          <div className=" flex flex-col justify-center">
        <div className="m-3 text-center text-gray-900 font-semibold">
        you can to delete your data for google privacy
        </div>
        <form
          onSubmit={(e) => userDelete(e)}
          // onSubmit={(e) => userDelete(e)}
          className="max-w-[400px] w-full mx-auto bg-gray-900 p-8 px-8 rounded-lg"
        >
          <h2 className="text-4xl dark:text-white font-bold text-center">
            Delete Your Data
          </h2>
          <div className="flex flex-col text-gray-400 py-2">
            <label htmlFor="phoneNumber">Select Country Code</label>
            <CountryCodeDropdown onSelect={handleCountrySelect} />
          </div>
          <div className="flex flex-col text-gray-400 py-2">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              id="phoneNumber"
              value={mob}
              required
              placeholder="1234567890"
              onChange={(e) => setMob(e.target.value)}
              className="mt-2 p-2 rounded-lg bg-gray-700 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
              type="text"
            />
          </div>
          <div className="flex flex-col text-gray-400 py-2">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              value={password}
              required
              placeholder="********"
              onChange={(e) => setPassword(e.target.value)}
              className="mt-2 p-2 rounded-lg bg-gray-700 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
              type="password"
            />
          </div>
          <div className="flex items-center justify-center">
            <p className="text-red-400">{errorMessage}</p>
          </div>
          {loading ? (
            <button
              type="button"
              className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded-lg disabled:cursor-not-allowed"
              disabled
            >
              Loading...
            </button>
          ) : (
            <button
              type="submit"
              className="w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 text-white font-semibold rounded-lg"
            >
              Delete your login data
            </button>
          )}
        </form>
      </div>
        </div>
      ) : (
        <p className="text-gray-800">No user information available</p>
      )}
    </div>
  );
};

export default User;
