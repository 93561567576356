import React from 'react'

const ShippingLink = () => {
    return (
        <a href="/shipping-delivery" className="cursor-pointer hover:underline">
          <span
            style={{
              color: "red",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
              fontFamily: "your-metal-font",
              fontSize: "17px",
              padding: "5px",
              border: "1px solid #d0d0d0",
              borderRadius: "5px",
            }}
          >
            Shipping
          </span>
        </a>
      );
}

export default ShippingLink