import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../features/userSlice";
import { FiAlignRight } from "react-icons/fi";
import ApiConstants from "../constants/ApiConstants";

const Home = () => {
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { tokens } = useSelector((state) => state?.white);

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(setToken(""));
    }
  }, [dispatch, location?.pathname]);

  return (
    <div className="w-full h-full relative bg-slate-800">
      <video
        src={ApiConstants.STATIC_IMAGE.VIDEO_HOME}
        className={`${
          open ? "w-full" : " w-3/4"
        } h-full object-cover fixed top-0 left-0`}
        autoPlay
        muted
        loop
      ></video>

      {tokens ? (
        <div className="absolute top-20  inset-20 flex items-center justify-center">
          <button
            className="bg-[#9bf388]  bg-opacity-50 text-white font-semibold p-5 rounded-lg text-lg tracking-widest uppercase focus:outline-none"
            style={{ width: "7cm" }}
            onClick={() => navigate("/dashboard")}
          >
            Relax
          </button>
        </div>
      ) : (
        <div
          className={`absolute top-60 ${
            open ? "w-full" : " w-3/4"
          }   flex flex-col items-center justify-center gap-4`}
        >
          <button
            className="bg-[#ef833b] bg-opacity-50 text-white font-semibold p-4 rounded-lg text-lg tracking-widest uppercase focus:outline-none"
            style={{ width: "4cm" }}
            onClick={() => navigate("/login")}
          >
            Login
          </button>
          <span
            className="ml-4 text-sm text-red-500"
            style={{ textAlign: "center" }}
          >
            Not have an account?{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.mahdi"
              className="underline  text-[#fff]"
            >
              Sign up through Mahdi App
            </a>
          </span>
        </div>
      )}
      {!tokens ? (
        <div className="items-end">
          <div className="py-10 px-7 absolute">
            <h2 className="text-4xl font-semibold text-white mb-3">Mahdi</h2>
            <div className="flex flex-col items-end"></div>
            <p className="mt-4 text-sm text-white">
              <span className=" text-base">Contact</span>
              <br />
              Email: shebeeradly@gmail.com
              <br />
              Phone: +91 9995103251
              <br />
              Address: Calicut, Kerala, India
              <br />
              Pin: 673586
            </p>
          </div>
          <div className=" py-10 px-7 flex flex-col items-end">
            <div
              className={`${open ? "absolute" : "relative"}`}
              style={{
                backgroundColor: "white",
                width: 35,
                height: 35,
                padding: 4,
                borderRadius: 5,
              }}
            >
              <FiAlignRight
                style={{ color: "red" }}
                size={25}
                className="cursor-pointer"
                onClick={() => setOpen(!open)}
              />
            </div>
            <span className="mx-4 text-sm text-white">|</span>
            <button
              onClick={() => navigate("/privacy-policy")}
              className="cursor-pointer hover:underline"
            >
              <span
                style={{
                  color: "#fff",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                  fontFamily: "your-metal-font",
                  fontSize: "17px",
                  padding: "5px",
                  border: "1px solid #d0d0d0",
                  borderRadius: "5px",
                }}
              >
                Privacy
              </span>
            </button>
            <span className="mx-4 text-sm text-white">|</span>
            <button
              onClick={() => navigate("/terms-conditions")}
              className="cursor-pointer hover:underline"
            >
              <span
                style={{
                  color: "#fff",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                  fontFamily: "your-metal-font",
                  fontSize: "17px",
                  padding: "5px",
                  border: "1px solid #d0d0d0",
                  borderRadius: "5px",
                }}
              >
                Terms
              </span>
            </button>
            <span className="mx-4 text-sm text-white">|</span>
            <button
              onClick={() => navigate("/shipping-delivery")}
              className="cursor-pointer hover:underline"
            >
              <span
                style={{
                  color: "#fff",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                  fontFamily: "your-metal-font",
                  fontSize: "17px",
                  padding: "5px",
                  border: "1px solid #d0d0d0",
                  borderRadius: "5px",
                }}
              >
                Shipping
              </span>
            </button>
            <span className="mx-4 text-sm text-white">|</span>
            <button
              onClick={() => navigate("/cancellation-refund")}
              className="cursor-pointer hover:underline"
            >
              <span
                style={{
                  color: "#fff",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
                  fontFamily: "your-metal-font",
                  fontSize: "17px",
                  padding: "5px",
                  border: "1px solid #d0d0d0",
                  borderRadius: "5px",
                }}
              >
                Refund
              </span>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Home;
