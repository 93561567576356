import React from "react";

const TermsLink = () => {
  return (
    <div className="flex items-center justify-center text-center">
      <div className=" w-7/12 bg-white shadow-md rounded-lg p-4">
        <h2 className="text-lg font-medium text-gray-900">
          Terms and Conditions
        </h2>
        <h1 className="text-lg font-bold text-gray-800 mt-2 ">
          In Using Website/App You Are deemed to have read and agreed to the
          following terms and conditions
        </h1>
        <p className=" text-gray-700 mt-4">
          MahdiTech grants you a limited, non-exclusive, non-transferable,
          revocable license to use our software for your personal or business
          use. All intellectual property rights, including copyrights,
          trademarks, and patents, related to our software are the property of
          MahdiTech.
        </p>
        <p className="text-gray-700 mt-4">
          You agree to use our software in accordance with all applicable laws
          and regulations. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.
        </p>
        <p className="text-gray-700 mt-4">
          **Contact information:**
          <br />
          shebeeradly@gmail.com
          <br />
          +91 9995103251
          <br />
          Address: Calicut, Kerala, India
          <br />
          Pin: 673586
        </p>
        <p className="text-gray-700 mt-4">
          **Effective date for policy:**
          <br />
          21-12-2023
        </p>
        <h4 className="text-lg font-semibold text-gray-800 mt-2 ">App Control section</h4>
        <p className="text-gray-700 mt-4">
          I want to inform you that whenever your invest for our control
          section project, you will return the money benefit only . But the
          authority for control and switching is as per the company decision.
        </p>
        <p className="text-gray-700 mt-4">
          **Limitation of liability and disclaimer of warranties:**
          <br />
          To the fullest extent permitted by applicable law, MahdiTech shall not
          be liable for any direct, indirect, incidental, special,
          consequential, or exemplary damages, including but not limited to
          damages for loss of profits, goodwill, use, data, or other intangible
          losses, arising out of or in connection with the use or inability to
          use the software, even if MahdiTech has been advised of the
          possibility of such damages.
          <br />
          MahdiTech makes no warranties or representations about the accuracy or
          completeness of the content of the software, and assumes no liability
          or responsibility for any errors or omissions in the content.
          MahdiTech does not warrant that the software will be uninterrupted or
          error-free, or that any defects will be corrected.
        </p>
        <p className="text-gray-700 mt-4">
          **Rules of conduct:**
          <br />
          By using our software, you agree to the following rules of conduct:
          <ul className="list-disc pl-4 mt-2 text-left">
            <li>
              You will not use the software for any illegal or unauthorized
              purpose.
            </li>
            <li>You will not violate any applicable laws or regulations.</li>
            <li>
              You will not post or transmit any content that is harmful,
              threatening, abusive, harassing, defamatory, vulgar, obscene,
              hateful, or racially, ethnically, or otherwise objectionable.
            </li>
            <li>
              You will not engage in any activity that could damage, disable,
              overburden, or impair the software or interfere with any other
              user's use of the software.
            </li>
            <li>
              You will not attempt to gain unauthorized access to the software
              or any related systems or networks.
            </li>
            <li>
              You will not collect or store any personal information about other
              users without their consent.
            </li>
          </ul>
        </p>
        <p className="text-gray-700 mt-4">
          **User restrictions:**
          <br />
          You agree that you will not do any of the following while using our
          software:
          <ul className="list-disc pl-4 mt-2">
            <li>
              Impersonate any person or entity, or falsely state or otherwise
              misrepresent your affiliation with a person or entity.
            </li>
            <li>
              Use any automated means, including but not limited to scripts,
              bots, spiders, or scrapers, to access or use the software.
            </li>
            <li>
              Transmit any viruses, worms, defects, Trojan horses, or other
              items of a destructive nature.
            </li>
            <li>
              Attempt to circumvent any security measures or access any
              restricted areas of the software.
            </li>
            <li>
              Modify, adapt, translate, reverse engineer, decompile,
              disassemble, or otherwise attempt to discover the source code of
              the software.
            </li>
            <li>
              Remove any copyright, trademark, or other proprietary rights
              notices from the software.
            </li>
            <li>
              Frame or mirror any part of the software without MahdiTech's
              express written permission.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default TermsLink;
